/**
* api接口统一管理
*/
import { axios } from '@/utils/request'

export const listProductBaseSpecValue = params => axios({
    url: '/api/product/system/productBaseSpecValue/list',
    method: 'get',
    params,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const addProductBaseSpecValue = params => axios({
    url: '/api/product/system/productBaseSpecValue/add',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const editProductBaseSpecValue = params => axios({
    url: '/api/product/system/productBaseSpecValue/edit',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const delProductBaseSpecValue = params => axios({
    url:'/api/product/system/productBaseSpecValue/del/' + params,
    method: 'post',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const selectByIdProductBaseSpecValue = params => axios({
    url: '/api/product/system/productBaseSpecValue/selectById/' + params,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})
